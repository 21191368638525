<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="4">
        <fancy-card
          color="red"
          icon="mdi-account-group"
          title="Mitglieder"
          :value="numMembers + numDeletedMembers"
        >
          <v-simple-table dense>
            <tbody>
              <tr>
                <td>Aktiv</td>
                <td>{{ numMembers }}</td>
              </tr>
              <tr>
                <td>Deaktiviert</td>
                <td>{{ numDeletedMembers }}</td>
              </tr>
            </tbody>
          </v-simple-table>
          <template v-slot:actions>
            <v-spacer></v-spacer>
            <v-btn text color="red" :to="{ name: 'Members' }">Anzeigen</v-btn>
          </template>
        </fancy-card>
      </v-col>
      <v-col cols="12" sm="4">
        <fancy-card
          color="blue"
          icon="mdi-account-group-outline"
          title="Gäste"
          :value="numGuests + numDeletedGuests"
        >
          <v-simple-table dense>
            <tbody>
              <tr>
                <td>Aktiv</td>
                <td>{{ numGuests }}</td>
              </tr>
              <tr>
                <td>Deaktiviert</td>
                <td>{{ numDeletedGuests }}</td>
              </tr>
            </tbody>
          </v-simple-table>
          <template v-slot:actions>
            <v-spacer></v-spacer>
            <v-btn text color="blue" :to="{ name: 'Guests' }">Anzeigen</v-btn>
          </template>
        </fancy-card>
      </v-col>
      <v-col cols="12" sm="4">
        <fancy-card
          color="green"
          icon="mdi-calendar"
          title="Touren"
          :value="statistics.events"
        >
          <template v-slot:actions>
            <v-spacer></v-spacer>
            <v-btn text color="green" :to="{ name: 'Events' }">Anzeigen</v-btn>
          </template>
        </fancy-card>
      </v-col>
      <v-col cols="12" sm="4">
        <fancy-card
          color="orange"
          icon="mdi-account-multiple-plus"
          title="Teilnahmen"
          :value="statistics.participations"
        >
          <v-simple-table dense>
            <tbody>
              <tr>
                <td>Teilnehmer</td>
                <td>{{ statistics.uniqueParticipants }}</td>
              </tr>
              <tr>
                <td>Ø / Tour</td>
                <td>
                  {{
                    (statistics.participations / statistics.events).toFixed(2)
                  }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </fancy-card>
      </v-col>
      <v-col cols="12" sm="4">
        <fancy-card
          color="red darken-3"
          icon="mdi-account-multiple-plus"
          title="Leitereinsätze"
          :value="statistics.guidings"
        >
          <v-simple-table dense>
            <tbody>
              <tr>
                <td>Leiter</td>
                <td>{{ statistics.uniqueGuides }}</td>
              </tr>
              <tr>
                <td>Ø / Tour</td>
                <td>
                  {{ (statistics.guidings / statistics.events).toFixed(2) }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </fancy-card>
      </v-col>
      <v-col cols="12" sm="4">
        <fancy-card
          color="grey darken-1"
          icon="mdi-email"
          title="Emails"
          :value="emailStats.delivered"
        >
          <v-simple-table dense>
            <tbody>
              <tr>
                <td>Gesendet</td>
                <td>{{ emailStats.delivered }}</td>
              </tr>
              <tr>
                <td>Geöffnet</td>
                <td>{{ emailStats.opened }}</td>
              </tr>
            </tbody>
          </v-simple-table>
          <template v-slot:actions>
            <v-spacer></v-spacer>
            <v-btn text color="grey darken-1" :to="{ name: 'MailStatistics' }"
              >Anzeigen</v-btn
            >
          </template>
        </fancy-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FancyCard from '@/components/cards/FancyCard'
import eventService from '@/services/event'
import emailService from '@/services/email'
import { mapState } from 'pinia'
import { usePersonStore } from '@/stores/person'

export default {
  name: 'AdminDashboard',
  components: { FancyCard },
  props: {},
  data() {
    return {
      statistics: {},
      emailStats: {},
    }
  },
  computed: {
    ...mapState(usePersonStore, ['numMembers', 'numDeletedMembers', 'numGuests', 'numDeletedGuests']),
  },
  watch: {},
  mounted() {
    eventService.statistics().then(data => { this.statistics = data })
    this.fetchEmailStats()
  },
  methods: {
    fetchEmailStats() {
      emailService.getOverallStats().then(data => {
        this.emailStats = data
      })
    },
  },
}

</script>

