<template>
  <v-card class="fancy-card">
    <div class="d-flex grow flex-wrap justify-space-between mx-3 pt-2">
      <v-sheet
        class="fancy-card__sheet pa-4"
        elevation="6"
        dark
        :color="color"
        max-height="90"
        width="auto"
      >
        <v-icon size="32">{{ icon }}</v-icon>
      </v-sheet>
      <div class="ml-4">
        <div class="ml-auto text-right">
          <div class="body-3 grey--text font-weight-light">{{ title }}</div>
          <div class="text-h5 font-weight-light text--primary">{{ value }}</div>
        </div>
      </div>
    </div>
    <v-divider class="mx-3" v-if="$slots.default || $slots.actions"></v-divider>
    <v-card-text class="py-1" v-if="$slots.default">
      <slot></slot>
    </v-card-text>
    <v-card-actions class="py-1" v-if="$slots.actions">
      <slot name="actions"></slot>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'FancyCard',
  props: {
    color: {
      type: String,
      default: 'primary',
    },
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number],
      required: false,
      default: 0,
    },
  },
}
</script>

<style lang="scss">
.fancy-card {
  margin-top: 20px;
  &__sheet {
    border-radius: 6px;
    position: relative;
    top: -20px;
  }
}
</style>
