import axios from '@/plugins/axios'
import { handleApiError } from '@/utils'


const END_POINT = '/api/v1/email'

function createPayload(mail, withAttachments = true) {
  const formData = new FormData()
  formData.append('subject', mail.subject)
  formData.append('to', mail.to)
  formData.append('text', mail.text)
  formData.append('html', mail.html)
  if(withAttachments) {
    for(const attachment of mail.attachments) {
      formData.append('attachment', attachment)
    }
  }
  return formData
}


function getMetaData() {
  return axios.get(`${END_POINT}/meta`)
    .then(response => {
      return response.data
    })
}

function sendMail(mail) {
  return axios.post(`${END_POINT}/send`, createPayload(mail, true))
    .then(response => {
      return response.data
    })
}

function previewMail(mail) {
  return axios.post(`${END_POINT}/preview`, createPayload(mail, false))
    .then(response => {
      return response.data
    })
}

function getMailingLists() {
  return axios.get(`${END_POINT}/lists`)
    .then(response => {
      return response.data
    })
}

function getMailingList(address) {
  return axios.get(`${END_POINT}/lists/${address}`)
    .then(response => {
      return response.data
    })
}

function createMailingList(data) {
  return axios.post(`${END_POINT}/lists`, data)
    .then(response => response.data)
}

function updateMailingList(address, data) {
  return axios.put(`${END_POINT}/lists/${address}`, data)
    .then(response => {
      return response.data
    })
}

function deleteMailingList(address) {
  return axios.delete(`${END_POINT}/lists/${address}`)
    .then(response => response.data)
}

function getMailingListMembers(id) {
  return axios.get(`${END_POINT}/lists/${id}/members`)
    .then(response => {
      return response.data
    })
}

function addMailingListMember(listAddress, data) {
  return axios.post(`${END_POINT}/lists/${listAddress}/members`, data)
    .then(response => response.data)
}

function removeMailingListMember(listAddress, memberAddress) {
  return axios.delete(`${END_POINT}/lists/${listAddress}/members/${memberAddress}`)
    .then(response => response.data)
}

function getStats(startDate, endDate) {
  return axios.get(`${END_POINT}/stats/${startDate}/${endDate}`)
    .then(response => response.data)
    .catch(e => {
      handleApiError(e)
    })
}


function getOverallStats() {
  return axios.get(`${END_POINT}/stats/overall`)
    .then(response => response.data)
}


export default {
  getMetaData,
  sendMail,
  previewMail,
  getMailingLists,
  getMailingList,
  createMailingList,
  updateMailingList,
  deleteMailingList,
  getMailingListMembers,
  addMailingListMember,
  removeMailingListMember,
  getStats,
  getOverallStats,
}
